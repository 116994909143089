<template>
  <div class="ScoreLibraryUpload">
    <ConfirmPop
      v-if="showConfirm"
      @cancel="showConfirm = false"
      @confirm="removeScore"
      :text="'Are you sure you want to delete the selected score?'"
    />
    <ConfirmPop
      v-if="showDeleteAllConfirm"
      @cancel="showDeleteAllConfirm = false"
      @confirm="deleteSelectedScores"
      :text="'Are you sure you want to delete all selected scores?'"
      :confirmMsg="'Yes, delete them'"
    />
    <div class="scoreContent">
      <ScoreLibrarySidebar />
      <div class="score-body">
        <ScoreLibraryTopbar :pageTitle="'EE Score Library'" :back="true" />
        <div class="right-widget">
          <div class="libraries-list">
            <router-link
              :to="{ name: 'ScoreLibraryUpload', params: { id: library.id } }"
              class="list-item"
              :class="checkLibrary(library.id) ? 'active' : ''"
              v-for="library in libraries"
              :key="library.id"
              >{{ library.name }}</router-link
            >
            <router-link :to="{ name: 'ManageLibraries' }" class="list-item">
              <i class="fa fa-plus"></i>
            </router-link>
          </div>
          <div class="form-section">
            <form action>
              <div class="form-group">
                <label for="Select Library">Select Library</label>
                <div class="label-group grid-3">
                  <select
                    name="title"
                    id="title"
                    v-model="searchQuery.curriculum"
                    @change="curriculumChanged()"
                  >
                    <option value="KungFuSightReading">
                      KungFu Sight Reading
                    </option>
                    <option value="PianoAdventure">Piano Adventure</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label for="Select Score Placement"
                  >Select Score Placement</label
                >
                <div class="label-group grid-3">
                  <select
                    name="title"
                    id="title"
                    v-model="searchQuery.gradeId"
                    @change="getLevels()"
                  >
                    <option value="">Select Grade</option>
                    <option
                      v-for="grade in grades"
                      :value="grade.gradeId"
                      :key="grade.gradeId"
                    >
                      {{ grade.gradeName }}
                    </option>
                  </select>
                  <select
                    name="title"
                    id="title"
                    v-model="searchQuery.levelId"
                    @change="getStages()"
                  >
                    <option value="">Select Level</option>
                    <option
                      v-for="level in levels"
                      :value="level.levelId"
                      :key="level.levelId"
                    >
                      {{ level.levelName }}
                    </option>
                  </select>
                  <select
                    name="title"
                    id="title"
                    v-model="searchQuery.stageId"
                    @change="getScores()"
                  >
                    <option value="">Select Stage</option>
                    <option
                      v-for="stage in stages"
                      :value="stage.stageId"
                      :key="stage.stageId"
                    >
                      {{ stage.stageName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <div class="label-group grid-m">
                  <!-- select for range -->
                  <select
                    name="range"
                    id="range"
                    :class="[
                      searchQuery.range == '' && showError ? 'error' : '',
                    ]"
                    @change="getScores()"
                    v-model="searchQuery.range"
                  >
                    <option value="null">Range</option>
                    <option
                      v-for="range in ranges"
                      :key="range.id"
                      :value="range.id"
                    >
                      {{ range.name }}
                    </option>
                  </select>
                  <!-- select for intervals -->
                  <select
                    name="interval"
                    id="interval"
                    :class="[
                      searchQuery.interval == '' && showError ? 'error' : '',
                    ]"
                    @change="getScores()"
                    v-model="searchQuery.interval"
                  >
                    <option value="null">Interval</option>
                    <option
                      v-for="interval in intervals"
                      :key="interval.id"
                      :value="interval.id"
                    >
                      {{ interval.name }}
                    </option>
                  </select>
                  <!-- select for rhythms -->
                  <EEDropdown
                    :items="rhythms"
                    class="dropd"
                    :class="[
                      searchQuery.rhythm == '' && showError ? 'error' : '',
                    ]"
                    :listName="'rhythm'"
                    :value="searchQuery.rhythm"
                    :long="true"
                    @selected="
                      (data) => {
                        searchQuery.rhythm = data;
                        getScores();
                      }
                    "
                  />
                  <!-- select for rests -->
                  <EEDropdown
                    :items="rests"
                    class="dropd"
                    :class="[
                      searchQuery.rest == '' && showError ? 'error' : '',
                    ]"
                    :listName="'Rest'"
                    :value="searchQuery.rest"
                    :long="true"
                    @selected="
                      (data) => {
                        searchQuery.rest = data;
                        getScores();
                      }
                    "
                  />
                  <!-- select for ties -->
                  <select
                    name="tie"
                    id="tie"
                    :class="[searchQuery.tie == '' && showError ? 'error' : '']"
                    @change="getScores()"
                    v-model="searchQuery.tie"
                  >
                    <option value="null">Tie</option>
                    <option v-for="tie in ties" :key="tie.id" :value="tie.id">
                      {{ tie.name }}
                    </option>
                  </select>
                  <!-- select for keySignature -->
                  <select
                    name="keySignature"
                    id="keySignature"
                    :class="[
                      searchQuery.keySignature == '' && showError
                        ? 'error'
                        : '',
                    ]"
                    @change="getScores()"
                    v-model="searchQuery.keySignature"
                  >
                    <option value="null">Key Signature</option>
                    <option
                      v-for="keySignature in keySignatures"
                      :key="keySignature.id"
                      :value="keySignature.id"
                    >
                      {{ keySignature.name }}
                    </option>
                  </select>
                  <!-- select for timeSignature -->
                  <select
                    name="timeSignature"
                    id="timeSignature"
                    :class="[
                      searchQuery.timeSignature == '' && showError
                        ? 'error'
                        : '',
                    ]"
                    @change="getScores()"
                    v-model="searchQuery.timeSignature"
                  >
                    <option value="null">Time Signature</option>
                    <option
                      v-for="timeSignature in timeSignatures"
                      :key="timeSignature.id"
                      :value="timeSignature.id"
                    >
                      {{ timeSignature.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label for="Select Library"
                  >Upload Scores [MusicXML format]</label
                >
                <div class="label-group grid-1">
                  <!-- dropzone -->
                  <section class="dropzone">
                    <div v-if="dropFiles.length == 0">
                      <b-field>
                        <b-upload
                          v-model="dropFiles"
                          multiple
                          drag-drop
                          expanded
                          accept=".musicxml"
                        >
                          <section class="section">
                            <div class="content">
                              <h3>Drag & Drop or</h3>
                              <p>browse</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span
                          v-for="(file, index) in dropFiles"
                          :key="index"
                          class="tag is-EEskyblue"
                        >
                          {{ file.name }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index)"
                          ></button>
                        </span>
                      </div>
                    </div>
                    <!-- html/css progress bar with text -->
                    <div v-else>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          :class="uploadProgress == 100 ? 'complete' : ''"
                          role="progressbar"
                          :style="{ width: uploadProgress + '%' }"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          Uploading scores {{ uploadProgress.toFixed(0) }}%
                        </div>
                      </div>
                    </div>
                  </section>
                  <p v-if="showUploadMsg">
                    {{ uploadedScores.length }} out of
                    {{ dropFiles.length }} scores uploaded. Please review and
                    set tagging below.
                  </p>
                </div>
              </div>
            </form>

            <div class="uploaded-section" v-if="uploadDone">
              <ScoreLibraryUploadedScores
                :uploadedScores="uploadedScores"
                @removeUploadedScore="
                  (data) => {
                    removeUploadedScore(data);
                  }
                "
                @publishScores="
                  (data) => {
                    publishScores(data);
                  }
                "
              />
            </div>

            <!-- preview listing -->
            <div v-show="startUpload == false">
              <div
                class="scores-section"
                v-if="allScores.length > 0 || searchText !== ''"
              >
                <div class="score-top">
                  <div class="score-top-left">
                    <!-- levels icon -->
                    <div class="levels-icon">
                      <i class="fa fa-list"></i>
                    </div>
                    <div class="search-input">
                      <input
                        type="text"
                        placeholder="Search score..."
                        @input="searchScores()"
                        v-model="searchText"
                      />
                    </div>
                  </div>
                  <div class="score-top-right">
                    <div
                      class="edit edit-item"
                      v-if="!startEdit"
                      @click="startEdit = true"
                    >
                      <i class="fa fa-edit"></i>
                      <label for>Edit</label>
                    </div>
                    <div class="edits" v-else>
                      <div
                        class="deselect-all edit-item"
                        @click="deselectAllScores()"
                        v-if="selectedScores.length == allScores.length"
                      >
                        <i class="fa fa-square edit-item"></i>
                        <label for>Deselect All</label>
                      </div>
                      <div
                        class="select-all edit-item"
                        @click="selectAllScores()"
                        v-else
                      >
                        <i class="fa fa-check-square"></i>
                        <label for>Select All</label>
                      </div>
                      <div
                        class="delete-btn edit-item"
                        @click="showDeleteAllConfirm = true"
                      >
                        <i class="fa fa-trash"></i>
                        <label for>Delete</label>
                      </div>
                      <div
                        class="export edit-item"
                        @click="exportSelectedScores()"
                      >
                        <i class="fa fa-download"></i>
                        <label for>Export</label>
                      </div>
                      <div class="cancel edit-item" @click="cancelEdit()">
                        <i class="fa fa-times"></i>
                        <label for>Cancel</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="score-section-body"
                  v-for="score in allScores"
                  :key="score.id"
                >
                  <div class="d-flex-btn">
                    <div class="score-identity">
                      <label for class="score-name">{{ score.name }}</label>
                      <!-- on and off toggler -->
                      <b-switch
                        v-model="score.scoreEnabled"
                        @click.prevent="switchScore(score)"
                      ></b-switch>
                    </div>
                    <div class="accompaniment" v-if="score.hasAccompaniment">
                      <audio controls>
                        <source
                          :src="
                            $store.state.assets_base_url +
                            score.accompanimentPath
                          "
                          type="audio/mp3"
                          v-if="score.accompanimentPath"
                        />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </div>
                  <div class="score-xml" :id="`score-xml${score.id}`">
                    <div class="circular-checkbox">
                      <!-- input checkbox -->
                      <input
                        type="checkbox"
                        class="square-checkbox"
                        @change="selectScore(score)"
                        v-model="score.checked"
                        v-if="startEdit"
                      />
                    </div>
                    <!-- <img :src="`/img/scores/${score.image}`" alt /> -->
                    <PreviewScore
                      :filename="scores_base_url + score.filePath"
                    ></PreviewScore>
                    <div
                      :ref="`sheetMusicContainer${score.id}`"
                      :id="`sheetMusicContainer${score.id}`"
                      :class="`sheetMusicContainer`"
                    ></div>
                  </div>
                  <div class="edit-score" v-show="score.checked">
                    <div class="score-placement">
                      <div class="input-group">
                        <label for="">Score name:</label>
                        <input type="text" v-model="score.name" />
                      </div>
                      <p>Placement</p>
                      <div class="input-group place">
                        <select
                          v-model="score.gradeId"
                          @change="getLevels(score)"
                        >
                          <option
                            v-for="grade in gradesForEdit"
                            :value="grade.gradeId"
                            :key="grade.gradeId"
                          >
                            {{ grade.gradeName }}
                          </option>
                        </select>
                        <select
                          v-model="score.levelId"
                          @change="getStages(score)"
                        >
                          <option
                            :value="score.levelId"
                            v-if="
                              levelsForEdit.length == 0 && score.levelId != null
                            "
                          >
                            {{ getLevel(score.levelId).levelName }}
                          </option>
                          <option
                            v-for="level in levelsForEdit"
                            :value="level.levelId"
                            :key="level.levelId"
                          >
                            {{ level.levelName }}
                          </option>
                        </select>
                        <select v-model="score.stageId">
                          <option
                            :value="score.stageId"
                            v-if="
                              stagesForEdit.length == 0 && score.stageId != null
                            "
                          >
                            {{ getStage(score.stageId).stageName }}
                          </option>
                          <option
                            v-for="stage in stagesForEdit"
                            :value="stage.stageId"
                            :key="stage.stageId"
                          >
                            {{ stage.stageName }}
                          </option>
                        </select>
                      </div>
                      <div class="input-group">
                        <!-- select for range -->
                        <select
                          name="range"
                          id="range"
                          v-model="score.range"
                          :class="[
                            score.range == '' && showError ? 'error' : '',
                          ]"
                        >
                          <option value>Range</option>
                          <option
                            v-for="range in ranges"
                            :key="range.id"
                            :value="range.id"
                          >
                            {{ range.name }}
                          </option>
                        </select>
                        <!-- select for intervals -->
                        <select
                          name="interval"
                          id="interval"
                          v-model="score.interval"
                          :class="[
                            score.interval == '' && showError ? 'error' : '',
                          ]"
                        >
                          <option value>Interval</option>
                          <option
                            v-for="interval in intervals"
                            :key="interval.id"
                            :value="interval.id"
                          >
                            {{ interval.name }}
                          </option>
                        </select>
                        <!-- select for rhythms -->
                        <EEDropdown
                          :items="rhythms"
                          class="dropd"
                          :class="[
                            score.rhythm == '' && showError ? 'error' : '',
                          ]"
                          :listName="'rhythm'"
                          :value="score.rhythm"
                          @selected="
                            (data) => {
                              score.rhythm = data;
                            }
                          "
                        />
                        <!-- select for rests -->
                        <EEDropdown
                          :items="rests"
                          class="dropd"
                          :class="[
                            score.rest == '' && showError ? 'error' : '',
                          ]"
                          :listName="'Rest'"
                          :value="score.rest"
                          @selected="
                            (data) => {
                              score.rest = data;
                            }
                          "
                        />
                        <!-- select for ties -->
                        <select
                          name="tie"
                          id="tie"
                          v-model="score.tie"
                          :class="[score.tie == '' && showError ? 'error' : '']"
                        >
                          <option value>Tie</option>
                          <option
                            v-for="tie in ties"
                            :key="tie.id"
                            :value="tie.id"
                          >
                            {{ tie.name }}
                          </option>
                        </select>
                        <!-- select for keySignature -->
                        <select
                          name="keySignature"
                          id="keySignature"
                          v-model="score.keySignature"
                          :class="[
                            score.keySignature == '' && showError
                              ? 'error'
                              : '',
                          ]"
                        >
                          <option value>Key Signature</option>
                          <option
                            v-for="keySignature in keySignatures"
                            :key="keySignature.id"
                            :value="keySignature.id"
                          >
                            {{ keySignature.name }}
                          </option>
                        </select>
                        <!-- select for timeSignature -->
                        <select
                          name="timeSignature"
                          id="timeSignature"
                          v-model="score.timeSignature"
                          :class="[
                            score.timeSignature == '' && showError
                              ? 'error'
                              : '',
                          ]"
                        >
                          <option value>Time Signature</option>
                          <option
                            v-for="timeSignature in timeSignatures"
                            :key="timeSignature.id"
                            :value="timeSignature.id"
                          >
                            {{ timeSignature.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="save-remove">
                      <div class="save-buttons">
                        <button class="save" @click="saveChanges(score)">
                          Save
                        </button>
                        <button
                          class="remove"
                          @click="
                            scoreToDelete = score;
                            showConfirm = true;
                          "
                        >
                          Remove Score
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="empty-section" v-else>
                <div class="empty-section-body">
                  <div class="empty-icon">
                    <i class="fa fa-folder-open"></i>
                  </div>
                  <div class="empty-text">
                    <h3>This Library is empty</h3>
                    <p>No scores found in this placement</p>
                  </div>
                </div>
              </div>
              <!-- <div :ref="`sheetMusicContainer${allScores[0].id}`" :class="`sheetMusicContainer`"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExportScores
      :scores="selectedScores"
      :exportData="exportData"
      v-if="startExportScores"
      @close="startExportScores = false"
    />
    <AdminRegFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import ScoreLibraryUploadedScores from "./components/ScoreLibraryUploadedScores.vue";
export default {
  name: "ScoreLibraryUpload",
  components: { ScoreLibraryUploadedScores },
  data() {
    return {
      grades: [],
      levels: [],
      stages: [],
      levelsForEdit: [],
      stagesForEdit: [],
      gradesForEdit: [],
      allScores: [],
      scoresToSearch: [],
      uploadedScores: [],
      selectedScores: [],
      startEdit: false,
      exportData: {
        grade: "",
        level: "",
        stage: "",
        module: "",
        curriculum: "",
      },
      searchQuery: {
        gradeId: "",
        levelId: "",
        stageId: "",
        moduleId: "",
        curriculum: "KungFuSightReading",
        range: null,
        interval: null,
        rhythm: null,
        rest: null,
        keySignature: null,
        timeSignature: null,
        tie: null,
      },
      showConfirm: false,
      showDeleteAllConfirm: false,
      scoreToDelete: null,
      libraries: [],
      tune: "/xml/libraryG1-L1-S2_A1-5-33.musicxml",
      dropFiles: [],
      uploadProgress: 0,
      startUpload: false,
      showUploadMsg: false,
      failedFiles: 0,
      filesToUpload: 0,
      uploadDone: false,
      scores_base_url: this.$store.state.scores_base_url,
      moduleId: null,
      searchText: "",
      ranges: [
        { id: 1, name: "Middle C Contrary motion" },
        { id: 2, name: "Middle C Similar Motion" },
        { id: 3, name: "Low C High C Position" },
        { id: 4, name: "EFGA Position" },
        { id: 5, name: "Beyond ledger line " },
      ],
      intervals: [
        { id: 1, name: "Unison-3rd" },
        { id: 2, name: "Without 4th" },
        { id: 3, name: "Special 5th" },
        { id: 4, name: "Special 4th" },
        { id: 5, name: "Unision-5th" },
        { id: 6, name: "Unision-6th" },
      ],
      rhythms: [
        { id: 1, name: "rhythm 1", code: "&#xE1D5; &#xE1D3; &#xE1D2;" },
        {
          id: 2,
          name: "rhythm 2",
          code: "&#xE1D5; &#xE1D3; &#xE1D3; &#xE1E7; &#xE1D2;",
        },
        { id: 3, name: "rhythm 3", code: "&#xE1D5; &#xE1D3; &#xE1D2;" },
        {
          id: 4,
          name: "rhythm 3",
          code: "&#xE1D5; &#xE1D3; &#xE1D3; &#xE1E7; &#xE1D2;",
        },
        { id: 5, name: "rhythm 3", code: "&#xE4E6; &#xE1D7;" },
        { id: 6, name: "rhythm 3", code: "&#xE1D5; &#xE1E7; &#xE1D7;" },
        {
          id: 7,
          name: "rhythm 3",
          code: "&#xE4E5; &#xE4E6; &#xE4E6; &#xE1D7;",
        },
      ],
      rests: [
        { id: 1, name: "Rest 1", code: "&#xE4F4;" },
        { id: 2, name: "Rest 1", code: "&#xE4E5; &#xE4F4;" },
        { id: 3, name: "Rest 1", code: "&#xE4F5; &#xE4E5; &#xE4F4;" },
      ],
      ties: [{ id: 1, name: "None" }],
      keySignatures: [
        { id: 1, name: "C+" },
        { id: 2, name: "G+" },
        { id: 3, name: "A-" },
        { id: 4, name: "F+" },
        { id: 5, name: "D-" },
        { id: 6, name: "C+G+F+" },
        { id: 7, name: "A-D-" },
      ],
      timeSignatures: [
        { id: 1, name: "4/4" },
        { id: 2, name: "2/4" },
        { id: 3, name: "3/4" },
        { id: 4, name: "3/8" },
      ],
      startExportScores: false,
      exportData: {
        gradeId: "",
        gradeName: "",
        levelId: "",
        levelName: "",
        stageId: "",
        stageName: "",
        moduleId: "",
        moduleName: "",
        curriculum: "KungFuSightReading",
      },
    };
  },
  methods: {
    cancelEdit() {
      this.startEdit = false;
      // make all score not checked
      this.allScores.forEach((score) => {
        score.checked = false;
      });
    },
    getGrade(id) {
      return this.grades.find((grade) => grade.gradeId == id);
    },
    getLevel(id) {
      return this.levels.find((level) => level.levelId == id);
    },
    getStage(id) {
      return this.stages.find((stage) => stage.stageId == id);
    },
    getRange(id) {
      return this.ranges.find((range) => range.id == id);
    },
    getInterval(id) {
      return this.intervals.find((interval) => interval.id == id);
    },
    getRhythm(id) {
      return this.rhythms.find((rhythm) => rhythm.id == id);
    },
    getRest(id) {
      return this.rests.find((rest) => rest.id == id);
    },
    getTie(id) {
      return this.ties.find((tie) => tie.id == id);
    },
    getKeySignature(id) {
      return this.keySignatures.find((keySignature) => keySignature.id == id);
    },
    getTimeSignature(id) {
      return this.timeSignatures.find(
        (timeSignature) => timeSignature.id == id
      );
    },
    getLibraries() {
      this.$startLoader();
      this.$store
        .dispatch("GET_LIBRARIES")
        .then((res) => {
          this.$stopLoader();
          this.libraries = res.data.modules;
        })
        .catch((err) => {
          this.$stopLoader();
          this.$showNotification(err.error);
        });
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    selectScore(score) {
      // score.checked = !score.checked;
      if (score.checked) {
        this.selectedScores.push(score);
        this.sele;
      } else {
        this.selectedScores = this.selectedScores.filter(
          (selectedScore) => selectedScore.id !== score.id
        );
      }
    },
    selectAllScores() {
      this.selectedScores = [];
      this.allScores.forEach((score) => {
        score.checked = true;
        this.selectedScores.push(score);
      });
    },
    deselectAllScores() {
      this.selectedScores = [];
      this.allScores.forEach((score) => {
        score.checked = false;
      });
    },
    switchScore(score) {
      console.log(score);
      this.$startLoader();
      this.$store
        .dispatch("TOGGLE_SCORE", {
          scoreEnabled: !score.scoreEnabled,
          id: score.id,
        })
        .then((res) => {
          score.scoreEnabled = !score.scoreEnabled;
          this.$stopLoader();
          this.$showNotification(res.message);
        })
        .catch((err) => {
          this.$stopLoader();
          this.$showNotification(err.error);
        });
    },
    getScoreTypeNumber(scoreType) {
      switch (scoreType) {
        case "Normal":
          return 0;
        case "Warmap":
          return 1;
        case "Exercise":
          return 2;
        default:
          return 0;
      }
    },
    saveChanges(score) {
      this.$startLoader();
      score.scoreType = this.getScoreTypeNumber(score.scoreType);
      this.$store
        .dispatch("UPDATE_SCORE", score)
        .then((res) => {
          this.$stopLoader();
          this.$showNotification(res.data.message);
          // reload page after 2 seconds
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.$stopLoader();
          this.$showNotification(err.error);
        });
    },
    removeScore() {
      this.showConfirm = false;
      this.$startLoader();
      this.$store
        .dispatch("DELETE_SCORE", this.scoreToDelete.id)
        .then((res) => {
          // remove score from allScores array
          this.allScores = this.allScores.filter(
            (score) => score.id !== this.scoreToDelete.id
          );
          // remove score from scoresToSearch array
          this.scoresToSearch = this.scoresToSearch.filter(
            (score) => score.id !== this.scoreToDelete.id
          );
          this.$stopLoader();
          this.$showNotification(res.data.message);
        })
        .catch((err) => {
          this.$stopLoader();
          this.$showNotification(err.error);
        });
    },
    searchScores() {
      console.log("searching scores");
      this.allScores = this.scoresToSearch.filter((score) =>
        score.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
    deleteSelectedScores() {
      this.allScores = this.allScores.filter(
        (score) => !this.selectedScores.includes(score)
      );
      this.showDeleteAllConfirm = false;
      this.$startLoader();
      // convert selected scores array to array of ids
      let selectedScoreIdsArray = this.selectedScores.map(
        (selectedScore) => selectedScore.id
      );
      if (selectedScoreIdsArray.length == 0) {
        this.$stopLoader();
        this.$showNotification("Please select at least one score");
        return;
      } else {
        this.$store
          .dispatch("DELETE_SELECTED_SCORES", {
            selected: selectedScoreIdsArray,
          })
          .then((res) => {
            this.$stopLoader();
            this.$showNotification(res.data.message);
          })
          .catch((err) => {
            this.$stopLoader();
            this.$showNotification(err.error);
          });
      }
    },
    exportSelectedScores() {
      this.$startLoader();
      // convert selected scores array to array of ids
      let selectedScoreIdsArray = this.selectedScores.map(
        (selectedScore) => selectedScore.id
      );
      if (selectedScoreIdsArray.length == 0) {
        this.$stopLoader();
        this.$showNotification("Please select at least one score");
        return;
      } else {
        this.exportData.grade = this.getGrade(this.selectedScores[0].gradeId);
        this.exportData.level = this.getLevel(this.selectedScores[0].levelId);
        this.exportData.stage = this.getStage(this.selectedScores[0].stageId);
        this.exportData.module = this.libraries.find(
          (library) => library.id == this.selectedScores[0].moduleId
        );
        this.$stopLoader();
        this.startExportScores = true;
        return;
        this.$store
          .dispatch("EXPORT_SCORES", { selected: selectedScoreIdsArray })
          .then((res) => {
            this.$stopLoader();
            this.$showNotification(res.message);
          })
          .catch((err) => {
            this.$stopLoader();
            this.$showNotification(err.error);
          });
      }
    },
    checkLibrary(libraryId) {
      // if library is equal to router parameter id, then return true
      if (libraryId == this.$route.params.id) {
        return true;
      } else {
        return false;
      }
    },
    removeUploadedScore(score) {
      console.log(score);
      this.uploadedScores = this.uploadedScores.filter(
        (uploadedScore) => uploadedScore.fileName !== score.fileName
      );
      // if uploadedScores array is empty, then refresh page after 2 seconds
      if (this.uploadedScores.length == 0) {
        // show notification
        this.$showNotification("All scores removed");
        this.startUpload = false;
        this.showUploadMsg = false;
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    },
    getGrades() {
      this.$startLoader();
      this.$store
        .dispatch("GET_GRADES")
        .then((res) => {
          this.$stopLoader();
          this.grades = res.data.grades;
          this.gradesForEdit = res.data.grades;
          this.searchQuery.levelId = "";
          this.searchQuery.stageId = "";
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getLevels(edit = null) {
      this.$startLoader();
      let id = this.searchQuery.gradeId;
      if (edit != null) {
        id = edit.gradeId;
        this.levelsForEdit = [];
      } else {
        this.levels = [];
        this.stages = [];
        this.searchQuery.levelId = "";
        this.searchQuery.stageId = "";
        this.allScores = [];
        this.selectedScores = [];
      }
      this.$store
        .dispatch("GET_LEVELS", id)
        .then((res) => {
          this.$stopLoader();
          if (edit) {
            this.levelsForEdit = res.data.levels;
            // edit.levelId = "";
            // edit.stageId = "";
            this.stagesForEdit = [];
          } else {
            this.levels = res.data.levels;
            this.stages = [];
            this.searchQuery.stageId = "";
          }
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getStages(edit = null) {
      this.$startLoader();
      let id = this.searchQuery.levelId;
      if (edit) {
        id = edit.levelId;
        this.stagesForEdit = [];
      } else {
        this.allScores = [];
        this.selectedScores = [];
        this.stages = [];
        this.searchQuery.stageId = "";
      }
      this.$store
        .dispatch("GET_STAGES", id)
        .then((res) => {
          this.$stopLoader();
          if (edit) {
            this.stagesForEdit = res.data.stages;
          } else {
            this.stages = res.data.stages;
          }
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getScores() {
      if (this.searchQuery.gradeId == "") {
        this.$showNotification("Please select a grade");
        return;
      }
      if (this.searchQuery.levelId == "") {
        this.$showNotification("Please select a level");
        return;
      }
      if (this.searchQuery.stageId == "") {
        this.$showNotification("Please select a stage");
        return;
      }
      if (this.searchQuery.curriculum == "") {
        this.$showNotification("Please select a library type");
        return;
      }
      // check each element on serachQuery object that is equal to string "null" and replace it with null
      for (let key in this.searchQuery) {
        if (this.searchQuery[key] == "null") {
          this.searchQuery[key] = null;
        }
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_SCORES", this.searchQuery)
        .then((res) => {
          this.$stopLoader();
          this.allScores = res.data.scores;
          this.scoresToSearch = res.data.scores;
          this.levelsForEdit = this.levels;
          this.stagesForEdit = this.stages;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.error) {
            this.$showNotification(err.error);
          } else {
            this.$showNotification("Something went wrong!!!");
            window.location.reload();
          }
        });
    },
    async uploadScores() {
      if (this.dropFiles.length > 0 && this.dropFiles.length <= 10) {
        this.startUpload = true;
        this.uploadedScores = [];
        this.failedFiles = 0;
        this.filesToUpload = this.dropFiles.length;
        this.uploadProgress = 0;
        let uploadIncreaseRate = 100 / this.filesToUpload;
        // upload files
        for (const score of this.dropFiles) {
          try {
            // get name of the file without extension to be uploaded
            let fileName = score.name.split(".")[0];
            const res = await this.$store.dispatch("UPLOAD_SCORE_FILE", {
              file: score,
            });

            let uploadedFile = {};
            uploadedFile.fileName = res.data.fileName;
            uploadedFile.filePath = res.data.filePath;
            uploadedFile.file = this.scores_base_url + uploadedFile.filePath;
            // uploadedFile.name = res.data.fileName without extension
            uploadedFile.name = fileName;
            uploadedFile.range = "";
            uploadedFile.interval = "";
            uploadedFile.rhythm = "";
            uploadedFile.rest = "";
            uploadedFile.keySignature = "";
            uploadedFile.timeSignature = "";
            uploadedFile.tie = "";
            uploadedFile.moduleId = this.moduleId;
            uploadedFile.gradeId = this.searchQuery.gradeId;
            uploadedFile.levelId = this.searchQuery.levelId;
            uploadedFile.stageId = this.searchQuery.stageId;

            this.uploadedScores.push(uploadedFile);
          } catch (err) {
            this.failedFiles++;
          }

          // increase uploadProgress until all files are uploaded
          this.uploadProgress += uploadIncreaseRate;
        }
      }
      if (this.dropFiles.length > 10) {
        this.$showNotification("You can upload maximum 10 files at a time");
        this.dropFiles = [];
        this.startUpload = false;
      }

      // upload progress by adding 1% every 50ms and add a complete class to the progress bar after 5s
      // let uploadProgress = 0;
      // let uploadProgressInterval = setInterval(() => {
      //   uploadProgress++;
      //   this.uploadProgress = uploadProgress;
      //   if (uploadProgress === 100) {
      //     clearInterval(uploadProgressInterval);
      //     this.showUploadMsg = true;
      //   }
      // }, 50);

      // setTimeout(() => {
      //   this.showUploadMsg = true;
      // }, 5000);
    },
    publishScores(scores) {
      if (scores.length > 0) {
        this.$startLoader();
        // loop through scores and make range, interval, rhythm, rest, keySignature, timeSignature and tie string if they are integers
        scores.forEach((score) => {
          // change moduleId to int
          score.moduleId = parseInt(score.moduleId);
          score.curriculum = this.searchQuery.curriculum;
        });

        this.$store
          .dispatch("ADD_SCORE", {
            scores: scores,
          })
          .then((res) => {
            this.$stopLoader();
            scores = [];
            this.dropFiles = [];
            this.uploadedScores = [];
            this.uploadDone = false;
            this.startUpload = false;
            this.showUploadMsg = false;
            this.uploadProgress = 0;
            this.$showNotification(res.data.message);
            // reload the page after 3s
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          })
          .catch((err) => {
            this.$stopLoader();
            if (err.error) {
              this.$showNotification(err.error);
            } else {
              this.$showNotification("Something went wrong!!!");
            }
          });
      } else {
        this.$showNotification("Please upload a score file");
      }
    },
    curriculumChanged() {
      this.getScores();
    },
  },
  mounted() {
    this.searchQuery.moduleId = this.$route.params.id;
    this.moduleId = this.$route.params.id;
    this.getLibraries();
    this.getGrades();
  },
  watch: {
    dropFiles: function () {
      this.uploadScores();
    },
    "$route.params.id": function () {
      this.searchQuery.moduleId = this.$route.params.id;
      this.moduleId = this.$route.params.id;
      this.dropFiles = [];
      this.uploadedScores = [];
      this.uploadDone = false;
      this.startUpload = false;
      this.showUploadMsg = false;
      this.uploadProgress = 0;
      this.allScores = [];
      this.searchQuery.gradeId = "";
      this.searchQuery.levelId = "";
      this.searchQuery.stageId = "";
    },
    uploadProgress: function () {
      if (this.uploadProgress > 100) {
        this.uploadProgress = 100;
      }
      if (this.uploadProgress == 100) {
        this.showUploadMsg = true;
        if (this.failedFiles > 0) {
          this.$showNotification(`${this.failedFiles} files failed to upload`);
        }
        this.uploadDone = true;
      }
      if (
        this.dropFiles.length ==
        this.uploadedScores.length + this.failedFiles
      ) {
        this.showUploadMsg = true;
        this.uploadProgress = 100;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$colors: (
  "is-EEskyblue": #3dd1d1,
);
$EEskyblue: #3dd1d1;
$EEorange: #ffc87d;
.ScoreLibraryUpload {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .score-body {
    .right-widget {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow-y: auto;
      // overflow-x: hidden;
      height: calc(88vh - 100px);
      padding-bottom: 100px;
      .libraries-list {
        display: flex;
        width: 100%;
        min-height: 55px;
        overflow-x: auto;
        .list-item {
          width: 150px;
          padding: 10px 0;
          min-width: 150px;
          background-color: #eee;
          border: none;
          border-radius: 5px;
          margin: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          color: #777;
          cursor: pointer;
          text-decoration: none;
          font-weight: 600;
          transition: all 0.7s ease-in-out;
          &:hover {
            background-color: orange;
            color: #fff;
          }
          &.active {
            background-color: orange;
            color: #fff;
          }
        }
      }
      .form-section {
        width: 99%;
        form {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          row-gap: 2rem;
          margin-top: 4rem;
          width: 100%;
          .form-group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            label {
              font-size: 1.25rem;
              font-weight: 600;
              color: $EEskyblue;
              margin-bottom: 10px;
            }
            .label-group {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              column-gap: 2rem;
              row-gap: 1rem;
              &.grid-3 {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
              }
              &.grid-m {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(7, 1fr);
              }
              .dropd {
                display: inline-block;
              }
              select {
                width: 100%;
                padding: 10px 0;
                background-color: #fff;
                box-shadow: 1px 2px 3px 4px #8d8d8d1a;
                border: none;
                border-radius: 5px;
                margin: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: #777;
                cursor: pointer;
                text-decoration: none;
                font-weight: 600;
                transition: all 0.7s ease-in-out;
                // style select dropdown options (height, hover color)
                option {
                  height: 50px;
                  &:hover {
                    background-color: $EEskyblue;
                    color: #fff;
                  }
                }
              }
            }
            .dropzone {
              width: 100%;
              height: auto;
              border: none;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              .section,
              .upload,
              .upload-draggable {
                height: 100%;
                background: #fff;
              }
              .content {
                h3,
                p {
                  display: inline-block;
                }
                h3 {
                  font-size: 1.25rem;
                  font-weight: 400;
                  margin-right: 5px;
                  // margin-bottom: 10px;
                }
              }
              .progress {
                width: 100%;
                height: 27px;
                background-color: #eee;
                border-radius: 50px;
                position: relative;
                .progress-bar {
                  width: 0%;
                  height: 100%;
                  background-color: $EEorange;
                  border-radius: 50px;
                  color: white;
                  font-size: 1.1rem;
                  transition: all 500ms ease-in-out;
                  // when progress bar is 100% wide, show the checkmark
                  &.complete {
                    background-color: $EEskyblue;
                    &:after {
                      content: "✔";
                      position: absolute;
                      right: 10px;
                      top: 0;
                      bottom: 0;
                      margin: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .scores-section {
        margin-top: 4rem;
        background: #fff;
        padding: 2rem;
        width: calc(100% - 4.4rem);
        border-radius: 20px;
        .score-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .score-top-left {
            display: flex;
            align-items: center;
            .levels-icon {
              font-size: 1.5rem;
              color: #777;
              margin-right: 1rem;
            }
            .search-input {
              width: 100%;
              input {
                width: 300px;
                padding: 10px 10px;
                background-color: #fff;
                box-shadow: 1px 2px 3px 4px #8d8d8d1a;
                border: none;
                border-radius: 5px;
                margin: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                color: #777;
                cursor: pointer;
                text-decoration: none;
                font-weight: 400;
                transition: all 0.7s ease-in-out;
              }
            }
          }
          .score-top-right {
            display: flex;
            align-items: center;
            .edits {
              display: flex;
            }
            .edit-item {
              display: flex;
              align-items: center;
              margin-right: 1.25rem;
              cursor: pointer;
              i {
                font-size: 1.25rem;
                color: #777;
                margin-right: 0.25rem;
              }
              label {
                font-size: 1.25rem;
                font-weight: 300;
                color: #777;
                cursor: pointer;
              }
            }
          }
        }
        .score-section-body {
          width: 100%;
          margin-top: 2rem;
          border-bottom: 2px dashed #dfdfdf;
          padding-bottom: 2rem;
          .score-identity {
            display: flex;
            align-items: center;
            width: 100%;
            .score-name {
              font-size: 1.25rem;
              font-weight: 500;
              color: #555;
              margin-right: 15px;
            }
            .on-off-tog {
              display: flex;
              align-items: center;
              .on-off-tog-btn {
                width: 50px;
                height: 25px;
                background-color: #ccc;
                border-radius: 25px;
                position: relative;
                cursor: pointer;
                .on-off-tog-btn-circle {
                  width: 20px;
                  height: 20px;
                  background-color: #fff;
                  border-radius: 50%;
                  position: absolute;
                  top: 2.5px;
                  left: 2.5px;
                  transition: all 0.5s ease-in-out;
                }
              }
              .on-off-tog-btn.on {
                background-color: $EEskyblue;
                .on-off-tog-btn-circle {
                  left: 27.5px;
                }
              }
            }
          }
          .score-xml {
            width: 100%;
            margin-top: 1rem;
            display: grid;
            grid-template-columns: 10% 90%;
            .circular-checkbox {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              .b-checkbox.checkbox input[type="checkbox"] + .check {
                border-radius: 50%;
              }
            }
            // img {
            //   width: 100%;
            //   height: 100%;
            //   object-fit: contain;
            // }
          }
          .score-placement {
            width: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: space-around;
            padding-top: 1rem;
            row-gap: 1rem;
            p {
              font-size: 1.25rem;
              font-weight: 500;
              color: #555;
              margin: 15px 0;
            }
            .input-group {
              display: flex;
              justify-content: center;
              align-items: center;
              column-gap: 2rem;
              &.place {
                select {
                  width: 200px;
                  height: auto;
                  padding: 10px 10px;
                  background-color: #fff;
                  box-shadow: 1px 2px 3px 4px #8d8d8d1a;
                  border: none;
                  border-radius: 5px;
                  font-size: 16px;
                  color: #555;
                }
              }
              &.placement-3 {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 2rem;
              }
              input {
                width: 200px;
                padding: 10px 10px;
                background-color: #fff;
                box-shadow: 1px 2px 3px 4px #8d8d8d1a;
                border: none;
                border-radius: 5px;
                font-size: 16px;
                color: #555;
              }

              select,
              .dropd {
                width: 120px;
                height: 30px;
                border: 1px #d9d9d9 solid;
                border-radius: 5px;
                padding: 0 10px;
                font-size: 1rem;
                color: #555;
                background: #fff;
                cursor: pointer;
                &.error {
                  border: 1px #ff00009b solid;
                }
              }
            }
          }
          .save-remove {
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-top: 2.5rem;
            .save-buttons {
              display: flex;
              column-gap: 1rem;
              button {
                width: 150px;
                padding: 10px;
                font-size: 18px;
                font-weight: 700;
                color: #555;
                border: none;
                border-radius: 10px;
                display: inline-block;
                cursor: pointer;
                &.save {
                  background-color: $EEskyblue;
                }
                &.remove {
                  background-color: transparent;
                  border-bottom: 1px #333 solid;
                  border-radius: 0;
                  padding-bottom: 5px;
                }
              }
            }
          }
        }
      }
      .empty-section {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        margin-top: 2rem;
        width: 99%;
        .empty-section-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          .empty-icon {
            font-size: 3rem;
            color: #777;
            margin-bottom: 1rem;
          }
          .empty-text {
            font-size: 1rem;
            color: #777;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
.square-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  // change the color of the checkmark and the background
  &:checked &.check {
    background-color: $EEskyblue;
    border-color: $EEskyblue;
    border-radius: 0;
    .checkmark {
      color: #fff;
    }
  }
}

.dropd {
  width: 120px;
  height: 43px;
  border: 1px solid #cccccc00;
  margin-top: 4px;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  color: #555;
  background: #fff;
  box-shadow: 1px 2px 3px 4px #8d8d8d1a;
  cursor: pointer;
  &.error {
    border: 1px #ff00009b solid;
  }
}
</style>
