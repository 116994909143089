import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import VueSkeletonLoader from "skeleton-loader-vue";
import Toast from "vue-toastification";
import OpenSheetMusicDisplay from "opensheetmusicdisplay";
import "vue-toastification/dist/index.css";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import ApexCharts from 'apexcharts'

// import VueConfirmDialog from 'vue-confirm-dialog'

import ScoreLibrarySidebar from "./views/admin/ScoreLibrary/components/ScoreLibrarySidebar.vue";
import ScoreLibraryTopbar from "./views/admin/ScoreLibrary/components/ScoreLibraryTopbar.vue";
import PreviewScore from "./views/admin/ScoreLibrary/components/PreviewScore.vue";
import EEDropdown from "./views/components/EEDropdown.vue";
import AdminRegFooter from "./views/components/AdminRegFooter.vue";
import AppMix from "./mixin/appMix.vue";
import VueHtml2pdf from 'vue-html2pdf';
// import VueHtml2Pdf from 'vuehtml2pdf';

// eventBus
export const eventBus = new Vue();

// notification popup
import ConfirmPop from "./views/components/ConfirmPop.vue";
import NotificationPop from "./views/components/NotificationPop.vue";
import ImageUploader from "./views/components/ImageUploader.vue";
import FileUploader from "./views/components/FileUploader.vue";
import Loading from "./views/components/Loading.vue";
import ExportScores from "./views/admin/ScoreLibrary/components/ExportScores.vue";

// register component
Vue.component("ScoreLibrarySidebar", ScoreLibrarySidebar);
Vue.component("ScoreLibraryTopbar", ScoreLibraryTopbar);
Vue.component("ConfirmPop", ConfirmPop);
Vue.component("NotificationPop", NotificationPop);
Vue.component("PreviewScore", PreviewScore);
Vue.component("EEDropdown", EEDropdown);
Vue.component("ImageUploader", ImageUploader);
Vue.component("FileUploader", FileUploader);
Vue.component("Loading", Loading);
Vue.component("AdminRegFooter", AdminRegFooter);
Vue.component("ExportScores", ExportScores);

// vue mixin
Vue.mixin(AppMix);

// libraries
Vue.use(Buefy);
Vue.component("skeleton-loader", VueSkeletonLoader);
const options = {
  position: "top-center",
  timeout: 2756,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};
Vue.use(Toast, options);
Vue.use(autoAnimatePlugin);
Vue.use(VueHtml2pdf);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
