<template>
    <div class="admin_container">

        <!-- Admin List -->
        <div v-if="adminToggle.adminList">
            <table>
                <tr>
                    <th>
                        <div class="headerHorizontal">
                            <input type="checkbox" @change="all = !all, checkAll()" :checked="all" :value="allchecked">
                            <span>Image</span>
                            <div>
                                <i class="fa  fa-chevron-up"></i>
                            </div>
                        </div>

                    </th>
                    <th>Name</th>
                    <th>
                        <div class="headerHorizontal">
                            <span>Username</span>
                            <i class="fa  fa-chevron-down"></i>
                        </div>
                    </th>

                    <th>
                        <div class="headerHorizontal">
                            <span>Email</span>
                            <i class="fa  fa-chevron-up"></i>
                        </div>

                    </th>
                    <th>
                        <div class="headerHorizontal">
                            <span>
                                Mobile
                            </span>
                            <i class="fa  fa-chevron-up"></i>
                        </div>
                    </th>
                    <th>age</th>
                    <th>Status</th>
                </tr>
                <template v-if="isLoading">
                    <tr v-for="row in 50" :key="row">
                        <td v-for="column in 7" :key="column">
                            <skeleton-loader :width="100" :height="10" type="rect" animation="fade"
                                style="
                                                                                                                                                                                                                                                                                                                                                margin: 0 auto" />
                        </td>
                    </tr>
                </template>
                <template v-if="!isLoading">
                    <tr v-for="user in adminUser" :key="user.userId">
                        <td>
                            <div class="headerHorizontal">
                                <input type="checkbox" :checked="all" class="Admin" name='Admin'
                                    @change="singlechecked(user.userId, $event)" :value='user.userId'>
                                <div class="adminAvatar">
                                    <!-- <img src="https://xsgames.co/randomusers/avatar.php?g=male" alt=""> -->
                                    <img :src="user.fileName ? $store.state.assets_base_url + user.fileName : imgPrev"
                                        alt="">
                                </div>

                                <div>
                                    <i class="fa  fa-pen" @click="editAdmin(user.userId)"></i>
                                </div>
                            </div>
                        </td>
                        <td>{{ user.fullName }}</td>
                        <td>{{ user.username }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.mobile }}</td>
                        <td>{{ user.age }}</td>
                        <td>
                            <div class="statusCheck">

                                <label for="status" @click="activateStatus(user.userId, user.isActive, $event)"
                                    :class="user.isActive ? 'special' : ''">
                                    <div></div>
                                </label>
                                <input type="checkbox" id="status">
                            </div>
                        </td>
                    </tr>
                </template>


            </table>
        </div>

        <!-- Edit admin -->
        <EditAdmin v-if="adminToggle.editAdmin.show" @triggered='triggered' />

        <!-- Add admin -->
        <AddAdmin v-if="adminToggle.addNewAdmin" @triggered='triggered' />

        <!-- Items delete box -->
        <div class="delete-box delete-show" ref="refDeleteBox">
            <div class="items">
                <div class="cross" @click="resetDeleteAction">
                    <i class="fa fa-times"></i>
                </div>
                <div class="selected-number">
                    <span>{{ [...new Set(itemToDelete)].length }}</span>
                </div>
                <h3>Items selected</h3>
            </div>
            <div class="actions">
                <button @click="confirmDelete = true"><i class="fa fa-trash"></i> Delete</button>
            </div>
        </div>

        <!-- delete all confirmation Box -->
        <div class="confirm_container" v-if="confirmDelete">
            <div class="box_conf">
                <p>Are you sure you want to delete all selected users?</p>
                <div class="action">
                    <div class="cancel">
                        <button class="cancelbtn" @click="confirmDelete = false">Cancel</button>
                    </div>
                    <div class="confirm">
                        <button class="confirmbtn" @click="deleteAdmin">
                            <Spinner size="18" v-if="isRequesting"></Spinner>
                            <span v-if="!isRequesting">Yes,Delete </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import AddAdmin from './AddAdmin.vue'
import EditAdmin from './EditAdmin.vue'
import Spinner from 'vue-simple-spinner'
import Logo from '../../../../../../src/assets/logo/adProfile.png'
export default {
    components: { EditAdmin, AddAdmin, Spinner },
    mounted() {
        let vm = this;
        vm.getAllAdmin();
    },
    data() {
        return {
            all: false,
            itemToDelete: [],
            imgPrev: Logo,
            allchecked: false,
            confirmDelete: false,
            adminToggle: this.$store.state.AdminstratorTab.AdminSwitcher,
            isLoading: true,
            isRequesting: false,
            adminUser: []


        }
    },
    props: ['SchlTeacherId'],
    watch: {
        '$store.state.AdminstratorTab.AdminSwitcher': function () {
            this.adminToggle = this.$store.state.AdminstratorTab.AdminSwitcher
        },
        '$store.state.searchadminTerm': function () {
            let vm = this;
            vm.getAllAdmin(vm.$store.state.searchadminTerm)
        }
    },
    methods: {
        triggered() {
            let vm = this;
            vm.getAllAdmin();
        },
        activateStatus(id, status, e) {
            let vm = this;
            let newStatus;
            if (status) {
                e.target.classList.remove('special')
                newStatus = 0;
                vm.$store.dispatch('POST_STATUS_ADMIN', {
                    userId: id,
                    status: newStatus
                })
                    .then(res => {
                        vm.getAllAdmin();
                    }).catch(e => {

                    })

            } else {
                e.target.classList.add('special')
                newStatus = 1;
                vm.$store.dispatch('POST_STATUS_ADMIN', {
                    userId: id,
                    status: newStatus
                })
                    .then(res => {
                        vm.getAllAdmin();
                    }).catch(e => {
                    })
            }
        },
        checkAll() {
            let vm = this;
            vm.allchecked = !vm.allchecked;
            vm.$refs.refDeleteBox.classList.remove('delete-show')
            if (vm.allchecked) {
                document.querySelectorAll('.Admin').forEach(el => {
                    vm.itemToDelete.push(+el.value)
                })
                vm.$refs.refDeleteBox.classList.remove('delete-show')

            } else {
                vm.$refs.refDeleteBox.classList.add('delete-show');
                vm.itemToDelete = [];
            }

        },
        singlechecked(id, e) {
            let vm = this;
            if (e.target.checked) {
                vm.$refs.refDeleteBox.classList.remove('delete-show')
                vm.itemToDelete.push(id)
            } else {
                let target = e.target.value;
                if (vm.itemToDelete.indexOf(+target) > -1) {
                    vm.itemToDelete.splice(vm.itemToDelete.indexOf(+target), 1)
                }
                vm.itemToDelete.length === 0 ? vm.$refs.refDeleteBox.classList.add('delete-show') : ''
            }
        },
        resetDeleteAction() {
            let vm = this;
            document.querySelectorAll('input[name="Admin"]:checked').forEach(CheckBox => {
                CheckBox.checked = false;
            })
            vm.itemToDelete = [];
            vm.$refs.refDeleteBox.classList.add('delete-show');
            vm.all = false;
            vm.allchecked = false;

        },
        editAdmin(id) {
            let vm = this;
            vm.$store.commit("VIEW_ADMIN_EDIT", { id })
        },
        getAllAdmin(term = '') {
            let vm = this;
            vm.$store.dispatch('POST_GET_ADMIN', {
                "page": 1,
                "size": 100,
                "searchQuery": term
            }).then((res => {
                vm.isLoading = false;
                vm.adminUser = res.data.users
            })).catch(e => {
                console.log(e)
            })
        },
        deleteAdmin(e) {
            let vm = this;
            vm.isRequesting = true;
            vm.$store.dispatch('POST_DELETE_ADMIN', { usersIds: vm.itemToDelete })
                .then(res => {
                    vm.getAllAdmin();
                    vm.isRequesting = false;
                    vm.confirmDelete = false;
                    vm.resetDeleteAction();
                    vm.isLoading = true;
                    vm.$toast.success("Admin deleted successful");
                }).catch(e => {
                    vm.isRequesting = false;
                    this.$toast.error("Something Went wrong!");
                })
        }

    },
}
</script>

<style lang="scss" scoped >
@import '../stylesConst/fonts.scss';

div {
    .organization_name {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        color: #666666;
    }

    h1 {
        font-weight: 600;
        color: $secondary_font_color;
        font-size: 16px;
    }

    table {
        width: 100%;
        border: none;
        color: #636363;
        ;

        tr {
            background: white;
            border: none;

            &:nth-child(odd) {
                background: #f3f3f7;
            }

            &:hover {
                background: $secondary_hover_color;
            }


            th {
                background: #d2d2d5;
                border: none;
                padding: 10px;
                font-size: 13px;
                border-right: 0;
                color: #666666;

                &:not(:last-child) {
                    border-right: 3px solid rgb(255, 255, 255);

                }


                input {
                    cursor: pointer;
                }

                div {
                    i {
                        cursor: pointer;
                    }
                }

            }

            td {
                text-align: center;
                padding: 10px;
                font-size: 13px;

                input {
                    cursor: pointer;
                }

                div {
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    i {
                        cursor: pointer;
                        height: 20px;
                        width: 20px;
                        background: rgb(90, 83, 83);
                        display: grid;
                        place-content: center;
                        font-size: 11px;
                        border-radius: 50%;
                        color: #d2d2d5;
                    }
                }

            }
        }
    }
}


.headerHorizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    .adminAvatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
            border: 2px solid $primary_bg_color;
        }
    }
}

.statusCheck {
    display: flex;
    justify-content: center;

    label {
        height: 20px;
        width: 40px;
        background: rgb(194, 194, 194);
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;

        div {
            height: 20px;
            width: 20px;
            background: rgb(235, 233, 233);
            border-radius: 50%;
            position: relative;
            right: 0;
            transition: all 0.2s ease;
            pointer-events: none !important;


        }

    }

    input {
        visibility: hidden;
    }
}

.special {
    background: #01cffe !important;

    div {
        right: -20px !important;
        background: rgb(235, 233, 233) !important;
        pointer-events: none !important;
    }
}

.admin_container {
    position: relative;

    .delete-box {
        position: fixed;
        bottom: 100px;
        width: 300px;
        border-radius: 15px;
        height: 150px;
        background: white;
        z-index: 100;
        left: 50%;
        transform: translate(-25%, 0);
        box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: space-between;
        font-size: 16px;
        transition: all 0.5s ease-in-out;

        .items {
            display: flex;
            align-items: center;

            .cross {
                display: flex;
                align-items: center;
                font-size: 18px;
                margin-right: 4px;
                cursor: pointer;

            }

            .selected-number {
                width: 40px;
                height: 40px;
                padding: 10px;
                border-radius: 50%;
                display: grid;
                place-content: center;
                background: $primary_bg_color;
                margin-right: 4px;

            }
        }

        .actions {
            button {
                padding: 8px 12px;
                border-radius: 4px;
                border: none;
                background: $primary_bg_color;
                outline: none;
                font-weight: 700;
                cursor: pointer;
                color: #2c3e50;
            }
        }
    }
}

.delete-box {
    position: fixed;
    bottom: 100px;
    width: 300px;
    border-radius: 15px;
    height: 150px;
    background: white;
    z-index: 100;
    left: 50%;
    transform: translate(-25%, 0);
    box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    font-size: 16px;
    transition: all 0.5s ease-in-out;

    .items {
        display: flex;
        align-items: center;

        .cross {
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-right: 4px;
            cursor: pointer;

        }

        .selected-number {
            min-width: 40px;
            min-height: 40px;
            padding: 10px;
            border-radius: 50%;
            display: grid;
            place-content: center;
            background: $primary_bg_color;
            margin-right: 4px;

        }
    }

    .actions {
        button {
            padding: 8px 12px;
            border-radius: 4px;
            border: none;
            background: $primary_bg_color;
            outline: none;
            font-weight: 700;
            cursor: pointer;
            color: #2c3e50;
        }
    }
}

.delete-show {
    bottom: -200px !important;
}

.confirm_container {

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #03030396;
    z-index: 100;
    display: grid;
    place-content: center;

    .box_conf {
        width: 358px;
        background-color: white;
        border-radius: 6px;
        color: #4a4a4a;
        display: block;
        padding: 1.25rem;

        p {
            text-align: center;
        }

        .action {
            display: flex;
            justify-content: center;
            column-gap: 12px;

            button {
                width: 98px;
                padding: 8px 4px;
                margin-top: 20px;
                cursor: pointer;
                border: none;
                font-weight: bold;
                border-radius: 6px;
                box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 9px rgb(216, 216, 216);
            }

            .cancelbtn {}

            .confirmbtn {
                background: #92e8ff;
            }
        }
    }
}
</style>