<template>
  <div class="ScoreLibraryUploadedScores" v-if="uploadedScores.length > 0">
    <ConfirmPop
      v-if="showConfirm"
      @cancel="showConfirm = false"
      @confirm="removeScore"
      :text="'Are you sure you want to delete the selected score?'"
    />
    <div v-if="!startReview && uploadedScores.length > 0">
      <div class="nav-widget">
        <div class="nav-dots">
          <div
            class="dot-item"
            :class="score.fileName == activeScore.fileName ? 'active' : ''"
            v-for="score in uploadedScores"
            :key="score.fileName"
            @click.prevent="changeActiveScore(score)"
          ></div>
        </div>
      </div>
      <button class="proceed-btn" @click="toggleReview()">
        Proceed to review all
        <i class="fas fa-angle-right"></i>
      </button>
      <div class="active-score">
        <form action class="placements">
          <div class="input-group">
            <!-- select for grades -->
            <select
              name="grade"
              id="grade"
              :class="[activeScore.gradeId == '' && showError ? 'error' : '']"
              v-model="activeScore.gradeId"
              @change="getLevels()"
            >
              <option value>Select Grade</option>
              <option
                v-for="grade in grades"
                :key="grade.gradeId"
                :value="grade.gradeId"
              >
                {{ grade.gradeName }}
              </option>
            </select>
            <!-- select for levels -->
            <select
              name="level"
              id="level"
              :class="[activeScore.levelId == '' && showError ? 'error' : '']"
              v-model="activeScore.levelId"
              @change="getStages()"
            >
              <option value>Select Level</option>
              <option
                v-for="level in levels"
                :key="level.levelId"
                :value="level.levelId"
              >
                {{ level.levelName }}
              </option>
            </select>
            <!-- select for stages -->
            <select
              name="stage"
              id="stage"
              :class="[activeScore.stageId == '' && showError ? 'error' : '']"
              v-model="activeScore.stageId"
            >
              <option value>Select Stage</option>
              <option
                v-for="stage in stages"
                :key="stage.stageId"
                :value="stage.stageId"
              >
                {{ stage.stageName }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <!-- select for range -->
            <select
              name="range"
              id="range"
              v-model="activeScore.range"
              :class="[activeScore.range == '' && showError ? 'error' : '']"
            >
              <option value>Range</option>
              <option v-for="range in ranges" :key="range.id" :value="range.id">
                {{ range.name }}
              </option>
            </select>
            <!-- select for intervals -->
            <select
              name="interval"
              id="interval"
              v-model="activeScore.interval"
              :class="[activeScore.interval == '' && showError ? 'error' : '']"
            >
              <option value>Interval</option>
              <option
                v-for="interval in intervals"
                :key="interval.id"
                :value="interval.id"
              >
                {{ interval.name }}
              </option>
            </select>
            <!-- select for rhythms -->
            <EEDropdown
              :items="rhythms"
              class="dropd"
              :class="[activeScore.rhythm == '' && showError ? 'error' : '']"
              :listName="'rhythm'"
              :value="activeScore.rhythm"
              @selected="
                (data) => {
                  activeScore.rhythm = data;
                }
              "
            />
            <!-- select for rests -->
            <EEDropdown
              :items="rests"
              class="dropd"
              :class="[activeScore.rest == '' && showError ? 'error' : '']"
              :listName="'Rest'"
              :value="activeScore.rest"
              @selected="
                (data) => {
                  activeScore.rest = data;
                }
              "
            />
            <!-- select for ties -->
            <select
              name="tie"
              id="tie"
              v-model="activeScore.tie"
              :class="[activeScore.tie == '' && showError ? 'error' : '']"
            >
              <option value>Tie</option>
              <option v-for="tie in ties" :key="tie.id" :value="tie.id">
                {{ tie.name }}
              </option>
            </select>
            <!-- select for keySignature -->
            <select
              name="keySignature"
              id="keySignature"
              v-model="activeScore.keySignature"
              :class="[
                activeScore.keySignature == '' && showError ? 'error' : '',
              ]"
            >
              <option value>Key Signature</option>
              <option
                v-for="keySignature in keySignatures"
                :key="keySignature.id"
                :value="keySignature.id"
              >
                {{ keySignature.name }}
              </option>
            </select>
            <!-- select for timeSignature -->
            <select
              name="timeSignature"
              id="timeSignature"
              v-model="activeScore.timeSignature"
              :class="[
                activeScore.timeSignature == '' && showError ? 'error' : '',
              ]"
            >
              <option value>Time Signature</option>
              <option
                v-for="timeSignature in timeSignatures"
                :key="timeSignature.id"
                :value="timeSignature.id"
              >
                {{ timeSignature.name }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <input
              type="text"
              placeholder="Score name"
              v-model="activeScore.name"
              :class="[activeScore.name == '' && showError ? 'error' : '']"
            />
            <FileUploader
              :file="activeScore.accompanimentPath"
              :filePath="'/img/library/'"
              @fileUploaded="activeScore.accompanimentPath = $event"
              :uploadText="'Upload Accompaniment'"
            />
          </div>
        </form>
        <div class="score-preview">
          <PreviewScore :filename="activeScore.file"></PreviewScore>
        </div>
        <div class="score-activations">
          <div class="activation-item">
            <label for>Accidental</label>
            <b-switch
              v-model="activeScore.accidental"
              @click.prevent="activeScore.accidental = !activeScore.accidental"
            ></b-switch>
          </div>
          <div class="activation-item">
            <label for>Articulation</label>
            <b-switch
              v-model="activeScore.articulation"
              @click.prevent="
                activeScore.articulation = !activeScore.articulation
              "
            ></b-switch>
          </div>
          <div class="activation-item">
            <label for>Dynamics</label>
            <b-switch
              v-model="activeScore.dynamics"
              @click.prevent="activeScore.dynamics = !activeScore.dynamics"
            ></b-switch>
          </div>
          <div class="activation-item">
            <label for>Score Enabled</label>
            <b-switch
              v-model="activeScore.scoreEnabled"
              @click.prevent="
                activeScore.scoreEnabled = !activeScore.scoreEnabled
              "
            ></b-switch>
          </div>
        </div>
        <div class="save-remove">
          <div class="save-buttons">
            <!-- <button class="save" @click="saveChanges()">Save tagging</button> -->
            <button
              class="remove"
              @click="
                scoreToDelete = activeScore;
                showConfirm = true;
              "
            >
              Remove Score
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="nav-widget">
        <p>Review all new scores for EE Adventure library</p>
      </div>
      <button class="back-btn" @click="startReview = false">
        <i class="fas fa-angle-left"></i>
      </button>
      <div
        class="active-score"
        v-for="(score, index) in uploadedScores"
        :key="index"
      >
        <label for class="score-name">{{ score.name }}</label>
        <div class="score-preview">
          <PreviewScore :filename="score.file"></PreviewScore>
          <div class="score-place">
            <p>
              Score Placement: {{ getGrade(score.gradeId).gradeName }},
              {{ getLevel(score.levelId).levelName }},
              {{ getStage(score.stageId).stageName }}
            </p>
          </div>
          <div class="terms">
            <!-- table of terms -->
            <table class="previewTable">
              <thead>
                <tr>
                  <th>Range</th>
                  <th>Interval</th>
                  <th>rhythm</th>
                  <th>Rest</th>
                  <th>Key Signature</th>
                  <th>Time Signature</th>
                  <th>Tie</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-if="score.range">{{ getRange(score.range).name }}</td>
                  <td v-else>None</td>
                  <td v-if="score.interval">
                    {{ getInterval(score.interval).name }}
                  </td>
                  <td v-else>None</td>
                  <td v-if="score.rhythm">
                    <span
                      class="with-symbols"
                      v-html="getRhythm(score.rhythm).code"
                    ></span>
                  </td>
                  <td v-else>None</td>
                  <td v-if="score.rest">
                    <span
                      class="with-symbols"
                      v-html="getRest(score.rest).code"
                    ></span>
                  </td>
                  <td v-else>None</td>
                  <td v-if="score.keySignature">
                    {{ getKeySignature(score.keySignature).name }}
                  </td>
                  <td v-else>None</td>
                  <td v-if="score.timeSignature">
                    {{ getTimeSignature(score.timeSignature).name }}
                  </td>
                  <td v-else>None</td>
                  <td v-if="score.tie">{{ getTie(score.tie).name }}</td>
                  <td v-else>None</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="score-activations">
          <div class="activation-item">
            <label for>Accidental</label>
            <b-switch
              v-model="score.accidental"
              @click.prevent="score.accidental = !score.accidental"
            ></b-switch>
          </div>
          <div class="activation-item">
            <label for>Articulation</label>
            <b-switch
              v-model="score.articulation"
              @click.prevent="score.articulation = !score.articulation"
            ></b-switch>
          </div>
          <div class="activation-item">
            <label for>Dynamics</label>
            <b-switch
              v-model="score.dynamics"
              @click.prevent="score.dynamics = !score.dynamics"
            ></b-switch>
          </div>
          <div class="activation-item">
            <label for>Score Enabled</label>
            <b-switch
              v-model="score.scoreEnabled"
              @click.prevent="score.scoreEnabled = !score.scoreEnabled"
            ></b-switch>
          </div>
        </div>
      </div>
      <div class="publish-btn">
        <button class="publish" @click="publishScores()">
          Publish to library
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScoreLibraryUploadedScores",
  props: {
    uploadedScores: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedScores: [],
      grades: [],
      levels: [],
      stages: [],
      ranges: [
        { id: 1, name: "Middle C Contrary motion" },
        { id: 2, name: "Middle C Similar Motion" },
        { id: 3, name: "Low C High C Position" },
        { id: 4, name: "EFGA Position" },
        { id: 5, name: "Beyond ledger line " },
      ],
      intervals: [
        { id: 1, name: "Unison-3rd" },
        { id: 2, name: "Without 4th" },
        { id: 3, name: "Special 5th" },
        { id: 4, name: "Special 4th" },
        { id: 5, name: "Unision-5th" },
        { id: 6, name: "Unision-6th" },
      ],
      rhythms: [
        { id: 1, name: "rhythm 1", code: "&#xE1D5; &#xE1D3; &#xE1D2;" },
        {
          id: 2,
          name: "rhythm 2",
          code: "&#xE1D5; &#xE1D3; &#xE1D3; &#xE1E7; &#xE1D2;",
        },
        { id: 3, name: "rhythm 3", code: "&#xE1D5; &#xE1D3; &#xE1D2;" },
        {
          id: 4,
          name: "rhythm 3",
          code: "&#xE1D5; &#xE1D3; &#xE1D3; &#xE1E7; &#xE1D2;",
        },
        { id: 5, name: "rhythm 3", code: "&#xE4E6; &#xE1D7;" },
        { id: 6, name: "rhythm 3", code: "&#xE1D5; &#xE1E7; &#xE1D7;" },
        {
          id: 7,
          name: "rhythm 3",
          code: "&#xE4E5; &#xE4E6; &#xE4E6; &#xE1D7;",
        },
      ],
      rests: [
        { id: 1, name: "Rest 1", code: "&#xE4F4;" },
        { id: 2, name: "Rest 1", code: "&#xE4E5; &#xE4F4;" },
        { id: 3, name: "Rest 1", code: "&#xE4F5; &#xE4E5; &#xE4F4;" },
      ],
      ties: [{ id: 1, name: "None" }],
      keySignatures: [
        { id: 1, name: "C+" },
        { id: 2, name: "G+" },
        { id: 3, name: "A-" },
        { id: 4, name: "F+" },
        { id: 5, name: "D-" },
        { id: 6, name: "C+G+F+" },
        { id: 7, name: "A-D-" },
      ],
      timeSignatures: [
        { id: 1, name: "4/4" },
        { id: 2, name: "2/4" },
        { id: 3, name: "3/4" },
        { id: 4, name: "3/8" },
      ],

      activeScore: null,
      scoreToDelete: null,
      showConfirm: false,
      startReview: false,
      scoresLoaded: false,
      showError: false,
    };
  },
  methods: {
    toggleReview() {
      if (
        this.activeScore.gradeId == "" ||
        this.activeScore.levelId == "" ||
        this.activeScore.stageId == "" ||
        this.activeScore.range == "" ||
        this.activeScore.interval == "" ||
        this.activeScore.rhythm == "" ||
        this.activeScore.rest == "" ||
        this.activeScore.tie == "" ||
        this.activeScore.keySignature == "" ||
        this.activeScore.timeSignature == "" ||
        this.activeScore.name == ""
      ) {
        this.showError = true;
        return;
      } else {
        this.startReview = true;
        this.showError = false;
      }
    },
    getGrades() {
      this.$startLoader();
      this.$store
        .dispatch("GET_GRADES")
        .then((res) => {
          this.$stopLoader();
          this.grades = res.data.grades;
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getLevels() {
      this.$startLoader();
      this.$store
        .dispatch("GET_LEVELS", this.activeScore.gradeId)
        .then((res) => {
          this.$stopLoader();
          this.levels = res.data.levels;
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getStages() {
      this.$startLoader();
      this.$store
        .dispatch("GET_STAGES", this.activeScore.levelId)
        .then((res) => {
          this.$stopLoader();
          this.stages = res.data.stages;
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    changeActiveScore(score) {
      if (
        score.gradeId == "" ||
        score.levelId == "" ||
        score.stageId == "" ||
        score.range == "" ||
        score.interval == "" ||
        score.rhythm == "" ||
        score.rest == "" ||
        score.tie == "" ||
        score.keySignature == "" ||
        score.timeSignature == "" ||
        score.name == ""
      ) {
        if (
          this.activeScore.gradeId == "" ||
          this.activeScore.levelId == "" ||
          this.activeScore.stageId == "" ||
          this.activeScore.range == "" ||
          this.activeScore.interval == "" ||
          this.activeScore.rhythm == "" ||
          this.activeScore.rest == "" ||
          this.activeScore.tie == "" ||
          this.activeScore.keySignature == "" ||
          this.activeScore.timeSignature == "" ||
          this.activeScore.name == ""
        ) {
          this.showError = true;
          return;
        } else {
          this.showError = false;
          this.activeScore = score;
        }
      } else {
        this.showError = false;
        this.activeScore = score;
      }
    },
    removeScore() {
      if (this.uploadedScores.length == 1) {
        this.$emit("removeUploadedScore", this.scoreToDelete);
      } else {
        this.$emit("removeUploadedScore", this.scoreToDelete);
        let remainingScores = this.uploadedScores.filter(
          (score) => score.fileName != this.scoreToDelete.fileName
        );
        this.activeScore = remainingScores[0];
      }
      this.showConfirm = false;
    },
    getGrade(id) {
      return this.grades.find((grade) => grade.gradeId == id);
    },
    getLevel(id) {
      return this.levels.find((level) => level.levelId == id);
    },
    getStage(id) {
      return this.stages.find((stage) => stage.stageId == id);
    },
    getRange(id) {
      return this.ranges.find((range) => range.id == id);
    },
    getInterval(id) {
      return this.intervals.find((interval) => interval.id == id);
    },
    getRhythm(id) {
      return this.rhythms.find((rhythm) => rhythm.id == id);
    },
    getRest(id) {
      return this.rests.find((rest) => rest.id == id);
    },
    getTie(id) {
      return this.ties.find((tie) => tie.id == id);
    },
    getKeySignature(id) {
      return this.keySignatures.find((keySignature) => keySignature.id == id);
    },
    getTimeSignature(id) {
      return this.timeSignatures.find(
        (timeSignature) => timeSignature.id == id
      );
    },
    changeSelectedrhythm(rhythm) {
      this.activeScore.rhythm = rhythm;
    },
    publishScores() {
      // loop through scores and check if it has accompanimentPath and set hasAccompaniment to true else false
      this.uploadedScores.forEach((score) => {
        if (score.accompanimentPath && score.accompanimentPath != "" && score.accompanimentPath != null  && score.accompanimentPath != undefined) {
          score.hasAccompaniment = true;
        } else {
          score.hasAccompaniment = false;
        }
      });
      this.$emit("publishScores", this.uploadedScores);
    },
  },
  mounted() {
    this.activeScore = this.uploadedScores[0];
    this.getGrades();
  },
  watch: {
    uploadedScores: function () {
      if (!this.scoresLoaded) {
        this.activeScore = this.uploadedScores[0];
        this.scoresLoaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
.ScoreLibraryUploadedScores {
  background: white;
  margin: 2rem 0;
  width: 100%;
  // padding: 2rem;
  border-radius: 20px;
  box-shadow: 2px 5px 10px 1px #b6b6b620;
  position: relative;
  padding-bottom: 4rem;
  .nav-widget {
    padding: 2rem;
    .nav-dots {
      display: flex;
      justify-content: center;
      column-gap: 20px;
      width: 100%;
      .dot-item {
        border: 1px #d9d9d9 solid;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        &.active {
          background: $EEskyblue;
        }
      }
    }
    p {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
  .proceed-btn {
    position: absolute;
    color: #555;
    background: $EEskyblue;
    font-size: 1rem;
    border-radius: 50px 0px 0px 50px;
    padding: 12px 25px;
    right: 0;
    top: 20px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    i {
      margin-left: 10px;
    }
  }
  .back-btn {
    position: absolute;
    color: #fff;
    background: $EEskyblue;
    left: 10px;
    top: 20px;
    width: 40px;
    height: 40px;
    border: none;
    font-size: 1.5rem;
    border-radius: 50%;
    box-shadow: 2px 2px 20px 1px #dedede;
    cursor: pointer;
  }
  .active-score {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .placements {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      width: 100%;
      .input-group {
        display: flex;
        justify-content: center;
        column-gap: 1rem;
        select,
        .dropd {
          width: 120px;
          height: 30px;
          border: 1px #d9d9d9 solid;
          border-radius: 5px;
          padding: 0 10px;
          font-size: 1rem;
          color: #555;
          background: #fff;
          cursor: pointer;
          &.error {
            border: 1px #ff00009b solid;
          }
        }
        input[type="text"] {
          width: 350px;
          border: none;
          border-radius: 5px;
          padding: 10px;
          font-size: 1.1rem;
          color: #555;
          background: #fff;
          cursor: pointer;
          font-weight: 500;
          margin-top: 2rem;
          box-shadow: 2px 2px 10px 3px #e8e8e8;
          &.error {
            border: 1px #ff00009b solid;
          }
        }
      }
    }
    .score-preview {
      margin-top: 2rem;
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 100%;
        &.mrgt {
          margin-top: 3rem;
        }
      }
      .score-place {
        p {
          font-size: 1.1rem;
          margin-top: 2rem;
        }
      }
    }
    .score-activations {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      column-gap: 2rem;
      .activation-item {
        display: flex;
        column-gap: 1rem;
        label {
          font-size: 1.1rem;
          font-weight: 500;
        }
      }
    }

    .save-remove {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      .save-buttons {
        display: flex;
        column-gap: 2rem;
        button {
          width: 150px;
          padding: 10px;
          font-size: 18px;
          font-weight: 700;
          color: #555;
          border: none;
          border-radius: 10px;
          display: inline-block;
          cursor: pointer;
          &.save {
            background-color: $EEskyblue;
          }
          &.remove {
            background-color: transparent;
            border-bottom: 1px #333 solid;
            border-radius: 0;
            padding-bottom: 5px;
          }
        }
      }
    }
    .score-name {
      position: absolute;
      color: #555;
      background: $EEskyblue;
      font-size: 1rem;
      border-radius: 0px 50px 50px 0px;
      padding: 12px 25px;
      left: 0;
      top: 20px;
      border: none;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .terms {
    width: 90%;
    border: 1px #888 solid;
    border-radius: 10px;
    margin-top: 1rem;
    .previewTable {
      border-radius: 10px;
      overflow: hidden;
      width: 100%;
      thead {
        background: $EEskyblue;
        border: 1px #888 solid;
        font-size: 0.9rem;
        border-radius: 10px;
      }
      td,
      th {
        padding: 5px 1px;
        text-align: center;
      }
      th {
        border-right: 1px #fff solid;
        &:nth-last-child(1) {
          border-right: none;
        }
      }
      tbody {
        td {
          padding: 5px 1px;
          border: 1px #888 solid;
          span {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
  .publish-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3.5rem;
    button {
      width: 200px;
      padding: 10px;
      font-size: 19px;
      font-weight: 700;
      color: #555;
      border: none;
      border-radius: 10px;
      display: inline-block;
      cursor: pointer;
      background-color: $EEskyblue;
      box-shadow: 3px 2px 10px 1px #cecece;
    }
  }
}
</style>